<template>
  <a-layout-header :style="{ position: 'fixed', zIndex: 1, width: '100%', height: '40px' }">
    <a-menu
        theme="dark"
        mode="horizontal"
        :default-selected-keys="[selected_key]"
        :style="{ lineHeight: '40px' }"
    >
      <a-menu-item v-for="item in menu_item" :key="item.key" @click="click_menu_item(item.key)">
        {{ item.name }}
      </a-menu-item>
    </a-menu>
  </a-layout-header>

</template>

<script>
export default {
  name: "Navibar",
  data() {
    return {
      selected_key: window.location.pathname,
      menu_item: [
        { key: '/', name: '主页' },
        { key: '/viewer_level', name: '观众投图' },
        { key: '/danmu', name: '弹幕列表' },
      ]
    }
  },
  methods:{
    click_menu_item(it) {
      if (it.startsWith("http"))
        window.location.href = it;
      else
        this.$router.push(it);
    }
  }
}
</script>

<style scoped>

</style>
