import { render, staticRenderFns } from "./Navibar.vue?vue&type=template&id=df2a3bca&scoped=true&"
import script from "./Navibar.vue?vue&type=script&lang=js&"
export * from "./Navibar.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df2a3bca",
  null
  
)

export default component.exports