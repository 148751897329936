<template>
  <a-breadcrumb :style="{ margin: '14px 0' }">
    <a-breadcrumb-item v-for="(it, i) in item" :key="'it_' + i">{{ it }}</a-breadcrumb-item>
  </a-breadcrumb>
</template>

<script>
export default {
  name: "Breadcrumb",
  props: {
    item: Array
  }
}
</script>

<style scoped>

</style>
