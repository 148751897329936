<template>
  <a-layout id="components-layout-demo-fixed">
    <Navibar />
    <a-layout-content :style="{ padding: '0 50px', marginTop: '50px' }">
      <Breadcrumb :item="['Home', '舞萌抽歌器']"/>
      <div :style="{ background: '#fff', padding: '24px', minHeight: '500px' }">
        <div :style="{ padding: '5px 0'}">
          <a-button type="primary" @click="filterOpen = !filterOpen">{{ filterOpen ? '折叠' : '展开' }}</a-button>
          <span v-if="this.ok">已加载 {{ songs.length }} 首歌</span>
          <span v-if="!this.ok">加载中……</span>
        </div>
        <transition name="gift">
          <div :style="{ padding: '5px 0'}" v-if="filterOpen && this.ok">
            <a-select size="large" mode="multiple" style="width: 100%"
                      :allowClear="true"
                      placeholder="选择等级" v-model="level">
              <a-select-option v-for="i in 28" :key="'level-choice-' + i" :value="i / 2.0 + 1.0" :label="i / 2.0 + 1.0">
                {{ i / 2.0 + 1.0 }}
              </a-select-option>
            </a-select>
          </div>
        </transition>
        <transition name="gift">
          <div :style="{ padding: '5px 0'}" v-if="filterOpen && this.ok">
            <a-select size="large" mode="multiple" style="width: 100%"
                      :allowClear="true" option-filter-prop="label"
                      placeholder="选择难度" v-model="difficulty">
              <a-select-option value="1" label="Basic">Basic</a-select-option>
              <a-select-option value="2" label="Advanced">Advanced</a-select-option>
              <a-select-option value="3" label="Expert">Expert</a-select-option>
              <a-select-option value="4" label="Master">Master</a-select-option>
              <a-select-option value="5" label="ReMaster">ReMaster</a-select-option>
            </a-select>
          </div>
        </transition>
        <transition name="gift">
          <div v-if="filterOpen && this.ok">
            <a-button type="primary" @click="start()">筛选</a-button>
            <a-button @click="clear()">清空</a-button>
          </div>
        </transition>
        <div :style="{ padding: '5px 0'}">
          <a-button type="primary" @click="getone()">抽取</a-button>
        </div>
        <div :style="{ padding: '5px 0'}">
          <transition-group name="ss" tag="p">
            <p style="font-size: 330%" v-if="item.song_level && !this.ok" :key="'s-' + item.id">{{ item.song_name }}({{item.song_level}})
              [{{ ['Easy', 'Basic','Advanced','Expert','Master','ReM'][item.song_difficulty] }}]</p>
            <p class="xuangeok" v-if="item.song_level && this.ok" :key="'s-' + item.id" style="font-size: 330%;color: #ffd600;text-shadow: #000 1px 0 0, #000 0 1px 0, #000 -1px 0 0, #000 0 -1px 0;">
              {{ item.song_name }}({{item.song_level}})
              [{{ ['Easy', 'Basic','Advanced','Expert','Master','ReM'][item.song_difficulty] }}]
            </p>
          </transition-group>
        </div>
      </div>
    </a-layout-content>
    <a-layout-footer :style="{ textAlign: 'center' }" @mouseenter="enter1()" @mouseleave="leave1()">
      {{ this.mousemove ? "阿扯爱肖战" : "Powered by Vue + Antdv" }}
    </a-layout-footer>
  </a-layout>
</template>

<script>
import Navibar from "@/components/Navibar";
import Breadcrumb from "@/components/Breadcrumb";
import axios from "axios";
export default {
  name: "Mai.vue",
  components: {Breadcrumb, Navibar},
  data() {
    return {
      filterOpen: true,
      level: [],
      difficulty: [],
      songs: [],
      timing: null,
      interval: 20,
      item: {},
      ok: true,
      mousemove: false
    }
  },
  methods: {
    clear() {
      this.level = [];
      this.difficulty = [];
    },
    start() {
      var level_str = this.level.join(",");
      var difficulty_str = this.difficulty.join(",");
      const that = this;
      this.ok = false;
      axios.get("/api/mai/filter?level=" + level_str + "&difficulty=" + difficulty_str)
      .then((res) => {
        that.songs = res.data.response;
        that.ok = true;
        this.filterOpen = false;
      }).catch((res) => {alert(res.toString());
        that.ok = true;});
      console.log(level_str);
      console.log(difficulty_str);
    },
    getone() {
      if (this.songs.length === 0) {
        alert("请先进行筛选再抽歌！");
        return;
      }
      this.timing = setTimeout(this.roll, 20);
      this.interval = 20;
      this.ok = false;
    },
    roll() {
      clearTimeout(this.timing);
      this.item = this.songs[Math.floor((Math.random()*this.songs.length))];
      if (this.interval < 170) {
        this.interval += 2;
        this.timing = setTimeout(this.roll, this.interval);
      } else {
        this.ok = true;
      }
    },
    enter1() {
      this.mousemove = true;
    },
    leave1() {
      this.mousemove = false;
    }
  }
}
</script>

<style scoped>

.gift-enter-active, .gift-leave-active {
  transition: all 0.2s;
}
.gift-enter, .gift-leave-to {
  opacity: 0;
  transform: translateY(30px);
}
.ss-enter-active, .ss-leave-active {
  transition: all 25ms;
}
.ss-enter, .ss-leave-to {
  opacity: 0;
}
</style>
